@font-face {
  font-family: 'Jost';
  src: url('./assets/fonts/Jost-Light.ttf');
  }

body {
  font-family: 'Jost', sans-serif;
  font-weight: 300;
}

.animation-wrapper {
  position: relative;
}

.animation-fade--in-out {
  animation: fadeIn ease 20s infinite;
  opacity: 0;
  position: absolute;
  top: 0;

}

.animation-fade--out-in {
  animation: fadeOut ease 20s infinite;
}

.content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 8rem 2rem 0;
}

.content__portrait {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .content__portrait {
    width: 40vw;
  }
}

.content__quote {
  font-size: 1.25rem;
  font-style: italic;
  margin: 1rem 0 0 0;
}

@media screen and (min-width: 768px) {
  .content__quote {
    font-size: 2.3vw;
    margin-top: 0;
    width: 40vw;
  }
}

.footer {
  align-items: center;
  border-top: 0.5px solid #f2b89a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8rem;
  padding: 0 1rem;
}

.footer__item {
  color: #000;
  margin: 0;
  padding: 1rem 0;
  text-decoration: none;
}

.footer__item:hover {
  color: #ff8d6f;
  text-decoration: underline;
}

.hero {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.hero__img {
  background-color: #818181;
  background-image: url('./assets/photos/DSC_4682-edit-2.jpg');
  background-position: 15% center;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.375s ease-in-out;
  z-index: -1;
}

.hero__title {
  color: #ff8d6f;
  font-size: 8.5vw;
  /* font-family: 'Suranna', serif; */
  margin: 0;
}

.main {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.nav {
  align-items: center;
  background-color: #fff;
  border-bottom: 0.5px solid #f2b89a;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 1rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.nav__brand {
  margin: 0;
  padding: 1rem 0;
}

.sociallist {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  margin:0;
  padding: 0;
  width: 100%;
}

.sociallist__item {
  margin: 1.5rem;
}

.sociallist__item__link {
  align-items: center;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}

.sociallist__item__link:hover {
  color: #ff8d6f;
  text-decoration: underline;
}

.sociallist__item__link__image {
  width: 30px;
}

.sociallist__item__link__label {
  margin: 0;
  padding-top: 1rem;
}

@keyframes fadeIn {
  40% { opacity: 0; }
  50% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeOut {
  40% { opacity: 1; }
  50% { opacity: 0; }
  80% { opacity: 0; }
  100% { opacity: 1; }
}


.glitch {
  text-decoration: none;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  /* color: #fff; */
  letter-spacing: 5px;
}

.glitch:before, 
.glitch:after {
  display: block;
  content: attr(data-glitch);
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: .8;
}
.glitch:after {
  color: #ff6fd6;
  z-index: -2;
}
.glitch:before {
  color: #6fffd4;
  z-index: -1;
}

.glitch:hover:before {
  animation: glitch .3s cubic-bezier(.25, .46, .45, .94) both 10
}
.glitch:hover:after {
  animation: glitch .3s cubic-bezier(.25, .46, .45, .94) reverse both 10
}

@media only screen and (max-width: 400px) {
  .glitch {
    font-size: 3em;
  }
}


@keyframes glitch {
  0% {
    transform: translate(0)
  }
  20% {
    transform: translate(-5px, 5px)
  }
  40% {
    transform: translate(-5px, -5px)
  }
  60% {
    transform: translate(5px, 5px)
  }
  80% {
    transform: translate(5px, -5px)
  }
  to {
    transform: translate(0)
  }
}